import React, { useState, useEffect } from 'react';

import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import Video from './Video'

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(0),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  img: {
    width: '100%',
    height: '15rem',
    objectFit: 'cover',
  },
  
}));

const tileData = [
  {
    img: 'https://material-ui.com/static/images/grid-list/vegetables.jpg',
    title: 'Image',
    author: 'author',
    cols: 2,
  },
  {
    img: 'https://material-ui.com/static/images/grid-list/honey.jpg',
    title: 'Image',
    author: 'author',
    cols: 2,
  },
  {
    img: 'https://material-ui.com/static/images/grid-list/hats.jpg',
    title: 'Image',
    author: 'author',
    cols: 2,
  },
  {
    img: 'https://material-ui.com/static/images/grid-list/plant.jpg',
    title: 'Image',
    author: 'author',
    cols: 2,
  },
  {
    img: 'https://picsum.photos/seed/picsum/200/300',
    title: 'Image',
    author: 'author',
    cols: 2,
  },
];

export default function Videos(props) {

  const classes = useStyles();
  const theme = useTheme();

  const handleVideoPlay = () => {
    props.onVideoPlayMain();
  };
  
  useEffect(() => {
    props.onLoadVideo()
  }, [])
  
  return (
    <React.Fragment>
      <Grid container spacing={3}>
        {tileData.map((tile) => (
          <Video src={tile.img} route={props.history} onVideoPlay={handleVideoPlay} />
        ))}
      </Grid>
    </React.Fragment>
  );
}