import React from 'react';
import Player from './Player'
import Playlist from './Playlist'

import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import CssBaseline from '@material-ui/core/CssBaseline';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  player: {
    height: "95vh",
    width: "100%",
    overflowX: "scroll",
    overflowY: "none",
  },
}));

export default function Videoplay(props) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <React.Fragment>
      <Grid container spacing={3} >
        <Grid item lg={8}  xs={12}>
          <Player />
        </Grid>
        <Grid item lg={4} xs={12} className={classes.player} >
          <Playlist />
        </Grid>
      </Grid>
    </React.Fragment>
  );

}