import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ReactPlayer from 'react-player'
import CssBaseline from '@material-ui/core/CssBaseline';

const useStyles = makeStyles((theme) => ({
  player: {
    width: '100% !important',
    height: 'auto !important',
  },
}));

export default function Player(props) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <React.Fragment>
      <ReactPlayer  className={classes.player} controls={1} url={"https://allyapps.sgp1.digitaloceanspaces.com/movie1/1080p.m3u8"} autoplay={1} />
    </React.Fragment>
  );

}