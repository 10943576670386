import React, { lazy, useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Nextvidoes from './Nextvideos'
import Loader from './Loader'
import ndjsonStream from 'can-ndjson-stream';

const useStyles = makeStyles((theme) => ({
  root: {
  },
}));

let videos = [];

export default function Playlist(props) {
  const classes = useStyles();
  const theme = useTheme();
  
  const [Tiles, setTiles] = useState()

  useEffect(() => {
    function writeToDOM(reader) {
      reader.read().then(
        ({ value, done }) => {
          if (done) {
            setTiles(videos.map((tile) => (
              <Nextvidoes img={tile.thumbnail} title={tile.title}/>
            )))
            console.log("The stream was already closed!");
          } else {
            // console.log(value.result.memories)
            videos.push(value.result.memories)
            // Recursively call
            writeToDOM(reader);
          }
        },
      );
    }        
    
    fetch("http://localhost:13000/api/v1/videos")
    .then((response) => {
      return ndjsonStream(response.body); //ndjsonStream parses the response.body
    })
    .then((videoStream) => {
      var reader = videoStream.getReader();
      writeToDOM(reader)
    });

  }, [])  

  return (
    <React.Fragment>
      <React.Suspense fallback={<Loader />}>
        {Tiles}
      </React.Suspense>
    </React.Fragment>
  );

}